import React, { useMemo, useEffect } from 'react'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import logo from '@/assets/logo.png'
import classnames from 'classnames'
import { Button } from 'antd'

import http from '@/utils/request'

import useReducers from '@/store/user'

import styles from './PageHeader.module.scss'

export default React.memo(function PageHeader({ className, title }) {
  const router = useRouter()
  const [state] = useReducers()

  const navs = useMemo(() => {
    return [{
      name: '首页',
      href: '/home'
    }, {
      name: '文章降重',
      href: '/chat/reducing'
    }, {
      name: '智能翻译',
      href: '/chat/translate'
    }, {
      name: '智能对话',
      href: '/chat/creation'
    }, {
      name: '长文创作',
      href: '/chat/long-creation'
    }, {
      name: '文章润色',
      href: '/chat/rewrite'
    }, {
      name: '插图生成',
      href: '/chat/image-generator'
    }, {
      name: '帮助中心',
      href: '/guide'
    }]
  }, [])

  return (
    <>
      <Head>
        <title>文友 - 智能写作助手：您的全方位写作难题解决方案 {title || ''}</title>
        <meta name="keywords" content="文章智能生成，文章自动降重，智能润色，智能问答，智能翻译"/>
        <meta name="description" content="文友是一款一站式的文章写作智能工具，基于最强大的ChatGPT技术，提供一系列功能，包括文章智能生成，文章自动降重，文章智能润色，智能问答，以及智能翻译，帮助用户解决各类文章写作中的难题。我们的目标是通过强大的AI技术手段，提升您的写作效率和质量，使写作变得简单而高效。"/>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={classnames(styles.container, className)}>
        <Link href="/home">
          <Image
            alt="logo"
            src={logo}
            height={34}
          />
        </Link>

        <div className={styles.navs}>
          {
            navs.map(nav => {
              return (
                <Link key={nav.href} href={nav.href} className={classnames({ [styles.active]: nav.href === router.pathname })}>
                  {nav.name}
                </Link>
              )
            })
          }
          {
            state.userInfo &&
            <Link href="/user">个人中心</Link>
          }
          {
            !state.userInfo &&
            <>
              <Link href="/user/login">
                <Button type="primary" size="small">
                  登录
                </Button>
              </Link>
              <Link href="/user/signup" className={styles.registerBtn}>
                <Button type="primary" size="small">
                  注册
                </Button>
              </Link>
            </>
          }
        </div>
      </div>
    </>
  )
})
