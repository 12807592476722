import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'

import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { DoubleRightOutlined } from '@ant-design/icons'
import { Carousel } from 'antd'

import PageHeader from '@/components/PageHeader'
import Copyright from '@/components/Copyright'

import logo from './assets/logo.png'

import icon1 from './assets/1.png'
import icon2 from './assets/2.png'
import icon3 from './assets/3.png'
import icon4 from './assets/4.png'
import icon5 from './assets/5.png'
import icon6 from './assets/6.png'
import icon7 from './assets/7.png'

import styles from './Home.module.scss'

export default React.memo(function Home() {
  const [carouselHeight, setCarouselHeight] = useState()
  const carouselRef = useRef()
  const ref = useRef()

  useEffect(() => {
    if (!carouselRef.current) {
      return
    }

    const { height } = carouselRef.current.getBoundingClientRect();
    setCarouselHeight(Math.max(height, 400))
  }, [carouselRef])

  useEffect(() => {
    let timer

    function updateSize() {

      if (timer) {
        clearTimeout(timer)
        timer = null
      }

      timer = setTimeout(() => {
        if (carouselRef.current) {
          const { height } = carouselRef.current.getBoundingClientRect();
          setCarouselHeight(Math.min(height, 700))
        }
        timer = null
      }, 300)
    }

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    }
  }, [])

  return (
    <div className={styles.container}>
      <PageHeader className={styles.header} title="中文论坛助手" />

      <div ref={carouselRef} className={styles.carousel}>
        {
          !!carouselHeight &&
          <Carousel className={styles.carousel} autoplay>
            <div>
              <div className={styles.creation} style={{ height: carouselHeight}}>
                <div className={styles.inner}>
                  <p>GPT-based Chinese version paper assistant</p>
                  <p>Easily solve all difficult problems in paper and writing</p>
                  <h4>基于GPT的中文版论文助手</h4>
                  <h3>轻松解决论文的难题</h3>
                  <div>
                    <Link href="/chat/creation">
                      查看详情
                      <DoubleRightOutlined style={{ marginLeft: 6 }}/>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.reducing} style={{ height: carouselHeight}}>
                <div className={styles.inner} >
                  <p>The strongest assistance for graduates</p>
                  <p>In 2023, the Al level ofthe paper dropped to the above line</p>
                  <h4>毕业生强力辅助</h4>
                  <h3>2023年论文AI降重现已上线</h3>
                  <div>
                    <Link href="/chat/reducing">
                      查看详情
                      <DoubleRightOutlined style={{ marginLeft: 6 }}/>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </Carousel>
        }
      </div>

      <div className={styles.features}>
        <Link href="/chat/creation">
          <Image
            alt=""
            src={icon5}
            height={30}
          />
        </Link>
        <Link href="/chat/long-creation">
          <Image
            alt=""
            src={icon6}
            height={30}
          />
        </Link>
        <Link href="/chat/reducing">
          <Image
            alt=""
            src={icon3}
            height={30}
          />
        </Link>
        <Link href="/chat/translate">
          <Image
            alt=""
            src={icon7}
            height={30}
          />
        </Link>
        <Link href="/chat/rewrite">
          <Image
            alt=""
            src={icon2}
            height={30}
          />
        </Link>
        <Link href="/chat/image-generator">
          <Image
            alt=""
            src={icon4}
            height={30}
          />
        </Link>
      </div>

      <Copyright/>
    </div>
  )
})
